<template>
  <date-picker :value="value" type="month" placeholder="Select date range" :open.sync="open" :disabled-date="dateRange"
    format="YYYY-MM-DD HH:mm:ss" :lang="language == 'th' ? langTH : 'en'" @input="handleInput" @open="initialTime()"
    @calendar-change="highLight" style="min-width: 240px; max-width: 100%">
    <template v-slot:footer>
      <button class="mx-btn mx-btn-text btn-toggle mr-2" @click="toggle" style="
            text-align: center;
            background-color: #198754;
            width: 100px;
            height: 30px;
            color: white;
            padding: 5px;
          ">
        {{ $t("message.confirm") }}
      </button>
    </template>
  </date-picker>
</template>
  
<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/th";

export default {
  name: "DateTimePicker",
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    type: {
      type: String,
      default: "datetime",
    },
  },
  data() {
    return {
      language: window.localStorage.getItem("language"),
      date: "",
      datetime: "",
      langTH: {
        days: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
        months: [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
        ],
      },
      open: false,
    };
  },
  mounted() {
    this.handleInput;
    if (this.value) {
      this.date = this.value;
    }
  },

  methods: {
    toggle() {
      this.open = !this.open;
    },

    highLight(date, oldDate, type) {
      // console.log(date, oldDate, type)
      setTimeout(() => {
        const startDate = moment(this.date[0]).format("YYYY-MM-DD");
        const endDate = moment(this.date[1]).format("YYYY-MM-DD");
        const elementNotCurrentMonth =
          document.getElementsByClassName("not-current-month");

        for (let i = 0; i < elementNotCurrentMonth.length; i++) {
          if (startDate === elementNotCurrentMonth[i].attributes.title.value) {
            elementNotCurrentMonth[i].classList.add("in-range-custom");
          } else if (
            endDate === elementNotCurrentMonth[i].attributes.title.value
          ) {
            elementNotCurrentMonth[i].classList.add("in-range-custom");
          } else {
            elementNotCurrentMonth[i].classList.remove("in-range-custom");
          }
        }
      });
    },

    initialTime() {
      if (!this.date) {
        this.date = [
          moment().startOf("day").toDate(),
          moment().endOf("day").toDate(),
        ];
      } else {
        setTimeout(() => {
          const startDate = moment(this.date[0]).format("YYYY-MM-DD");
          const endDate = moment(this.date[1]).format("YYYY-MM-DD");
          const elementNotCurrentMonth =
            document.getElementsByClassName("not-current-month");

          for (let i = 0; i < elementNotCurrentMonth.length; i++) {
            if (
              startDate === elementNotCurrentMonth[i].attributes.title.value
            ) {
              elementNotCurrentMonth[i].classList.add("in-range-custom");
            } else if (
              endDate === elementNotCurrentMonth[i].attributes.title.value
            ) {
              elementNotCurrentMonth[i].classList.add("in-range-custom");
            } else {
              elementNotCurrentMonth[i].classList.remove("in-range-custom");
            }
          }
        });
      }
    },

    dateRange(date, currentValue) {
      // console.log('currentValue ', new Date(currentValue).getTime() )

      let datatime = new Date()
      datatime.setDate(1)

      // console.log('date', moment(date).format('YYYY MM DD'))
      // console.log('datatime', moment(new Date(datatime.getTime())).format('YYYY MM DD') )

      // return  date > new Date(new Date().setHours(0, 0, 0, 0));
      return (
        moment(new Date(datatime.getTime())).format('YYYY MM DD') < moment(date).format('YYYY MM DD')
        // || moment(new Date(datatime.getTime())).format('YYYY MM DD') == moment(date).format('YYYY MM DD')
      )
    },

    pervious1day(emit) {
      emit([moment().startOf("day").toDate(), moment().toDate()]);
    },

    pervious7day(emit) {
      emit([
        moment().subtract(7, "days").startOf("day").toDate(),
        moment().toDate(),
      ]);
    },

    pervious1month(emit) {
      emit([
        moment().subtract(30, "days").startOf("day").toDate(),
        moment().toDate(),
      ]);
    },

    changeLanguage(index) {
      if (index === 0) {
        this.$i18n.locale = "th";
        this.flag = `flag-icon flag-icon-th`;
        window.localStorage.setItem("language", "th");
      } else if (index === 1) {
        this.$i18n.locale = "gb";
        this.flag = `flag-icon flag-icon-gb`;
        window.localStorage.setItem("language", "gb");
      }
      this.$store.state.nav = this.$t("message.nav");
    },

    handleInput(e) {
      // this.date = e;
      this.date = moment(e).endOf('month').toDate();
      this.$emit("input", this.date);
      // console.log('e befor',moment(e).format("YYYY-MM-DD"));
      // console.log('e after', moment(moment(e).endOf('month').toDate()).format("YYYY-MM-DD"));
      // console.log(moment(this.date).format("YYYY-MM-DD"));

      setTimeout(() => {
        const startDate = moment(this.date[0]).format("YYYY-MM-DD");
        const endDate = moment(this.date[1]).format("YYYY-MM-DD");
        const elementNotCurrentMonth =
          document.getElementsByClassName("not-current-month");

        // console.log(startDate);
        // console.log(endDate);

        for (let i = 0; i < elementNotCurrentMonth.length; i++) {
          // console.log(elementNotCurrentMonth[i].attributes.title.value);
          if (startDate === elementNotCurrentMonth[i].attributes.title.value) {
            elementNotCurrentMonth[i].classList.add("in-range-custom");
            // console.log(elementNotCurrentMonth[i]);
          } else if (
            endDate === elementNotCurrentMonth[i].attributes.title.value
          ) {
            elementNotCurrentMonth[i].classList.add("in-range-custom");
            // console.log(elementNotCurrentMonth[i]);
          } else {
            elementNotCurrentMonth[i].classList.remove("in-range-custom");
          }
        }
      });
    },
  },
};
</script>
  
<style>
.in-range,
.in-range-custom {
  background-color: #dbedfb !important;
}

.mx-datepicker-footer {
  height: 45px !important;
}

/* .mx-datepicker-btn-confirm {
    width: 100px;
    height: 30px;
    background-color: #198754;
    color: #fff;
  } */

.btn-datetime:hover {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(115, 220, 252) !important;
}

.btn-toggle:hover {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(147, 207, 147) !important;
}

.mx-input {
  height: 36px;
  font-size: 13px;
}

.mx-datepicker-header {
  display: flex;
}

/* .mx-datepicker-sidebar {
    width: 130px !important;
  }
  
  .mx-datepicker-content {
    margin-left: 130px !important;
  } */

/* สีพิ้นหลังปฎิทินทั้งหมด */
.mx-datepicker-main {
  font: 14px/1.5 "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei",
    sans-serif;
  color: #73879c;
  background-color: #fff;
  border: 1px solid #e8e8e8;
}

/* สีของเมาส์ */
.mx-calendar-content .cell:hover {
  color: #73879c;
  background-color: #f3f9fe;
  /* color: ccc;
    background-color: #ffffff; */
}

/* สีของวันเริ่มต้นกับสิ้นสุดที่เลือก */
.mx-calendar-content .cell.active {
  color: #fff;
  background-color: #1284e7;
}

/* สีของช่วงเวลาที่เลือก */
.mx-calendar-content .cell.in-range,
.mx-calendar-content .cell.hover-in-range {
  color: #73879c;
  background-color: #dbedfb;
}

.mx-calendar-content .cell.disabled {
  cursor: not-allowed;
  color: #ccc;
  background-color: #f3f3f3;
}

.mx-table-date .cell.not-current-month {
  color: #ccc;
  /* background: none; */
}
</style>
  